new WOW({ offset: 70 }).init();

$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	if ($('#HomepageMarker').length > 0) {
		var testimonialAuthors = $(".RotatorTestimonialContent a[itemprop='author']");
		if (testimonialAuthors.length > 0) {
			$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
				$(this)[0].nextSibling.nodeValue = '';
			});
		}

		$('.services__link').each(function (i, el) {
			$(el).addClass("wow fadeInUp2").attr("data-wow-delay", (i * 0.15) + "s");
		});

	} else {

	}

	function fixHeights() {
		$(".RotatorTestimonialContent").sameHeight();
		$(".servces__link").sameHeight();
	}

	setTimeout(fixHeights, 500);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 500);
	});

});

